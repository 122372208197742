import Portfolio from "./components/Portfolio";
import AnimatedCursor from "react-animated-cursor";
function App() {
  return (
    <>
      <AnimatedCursor
        innerSize={25}
        outerSize={20}
        color="136, 192, 208"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
      <Portfolio />
    </>
  );
}

export default App;
