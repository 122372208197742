import React, { useState, useEffect } from "react";
import {
  ChevronRight,
  Github,
  Linkedin,
  Twitter,
  Mail,
  ExternalLink,
  Skull,
  Code,
} from "lucide-react";

const TypewriterEffect = ({
  texts,
  typingSpeed = 150,
  eraseSpeed = 100,
  pauseTime = 2000,
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let timer;
    if (isTyping) {
      if (currentText.length < texts[currentIndex].length) {
        timer = setTimeout(() => {
          setCurrentText(texts[currentIndex].slice(0, currentText.length + 1));
        }, typingSpeed);
      } else {
        setIsTyping(false);
        timer = setTimeout(() => {
          setIsTyping(true);
        }, pauseTime);
      }
    } else {
      if (currentText.length > 0) {
        timer = setTimeout(() => {
          setCurrentText(texts[currentIndex].slice(0, currentText.length - 1));
        }, eraseSpeed);
      } else {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length); // move to next text
        setIsTyping(true); // Start typing again
      }
    }
    return () => clearTimeout(timer);
  }, [
    currentText,
    currentIndex,
    isTyping,
    texts,
    typingSpeed,
    eraseSpeed,
    pauseTime,
  ]);
  return <span className="text-nord8">{currentText}</span>;
};

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [scrollProgress, setScrollProgress] = useState(0);
  const [animationState, setAnimationState] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["home", "about", "experience", "projects", "contact"];
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            element.classList.add("animate-fade-in");
          } else {
            element.classList.remove("animate-fade-in");
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navItems = [
    { id: "about", label: "About" },
    { id: "experience", label: "Experience" },
    { id: "projects", label: "Projects" },
    { id: "contact", label: "Contact" },
  ];

  const experiences = [
    {
      title: "Software Engineer Intern",
      company: "Lending Buddha",
      date: "June 2024 – August 2024",
      responsibilities: [
        "Fixed bugs and added features to REST APIs, enhancing system performance.",
        "Achieved 96% test coverage with comprehensive unit tests.",
        "Developed a REST API with Flask and PostgreSQL for efficient LMS data management.",
        "Developed an analytics dashboard for LMS using React and Chart.js.",
      ],
    },
  ];

  const projects = [
    {
      title: "UnityDocs",
      description: "Secure document management with Spring Boot",
      link: "https://github.com/r0ckYr/UnityDocs/",
      image: "unitydocs.jpg",
    },
    {
      title: "PixelVision",
      description: "Web app for automated medical image classification",
      link: "https://github.com/r0ckYr/PixelVision",
      liveLink: "https://pixelvision.rockyrp.com",
      image: "pixelvision.png",
    },
    {
      title: "Online Code Editor",
      description: "Online code editor supporting multiple languages",
      link: "https://github.com/r0ckYr/online-code-editor",
      liveLink: "https://codeeditor.rockyrp.com",
      image: "onlinecodeeditor.png",
    },
    {
      title: "BERT-Powered QnA Extension",
      description: "Chrome extension using NLP techniques and Google BERT",
      link: "https://github.com/r0ckYr/ChromeQnAExtension",
      image: "bertqna.png",
    },
    {
      title: "HealthCare Chatbot",
      description:
        "A user-friendly chatbot that answers queries on healthcare topics.",
      link: "https://github.com/r0ckYr/HealthcareChatBot",
      liveLink: "https://healthbot.rockyrp.com",
      image: "chatbot.png",
    },
    {
      title: "Ludo Game",
      description: "Multiplayer Ludo Game",
      link: "https://github.com/r0ckYr/Ludo",
      liveLink: "https://ludo.rockyrp.com",
      image: "ludo.png",
    },
  ];

  return (
    <div className="bg-nord0 text-nord6 min-h-screen">
      <nav className="fixed w-full bg-nord1 bg-opacity-90 p-4 backdrop-blur-md z-10 shadow-lg">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <a
            href="#home"
            className="text-4xl font-extrabold text-nord7 hover:text-nord8 transition-all transform hover:scale-105"
          >
            R
          </a>
          <ul className="flex space-x-10">
            {navItems.map((item) => (
              <li key={item.id}>
                <a
                  href={`#${item.id}`}
                  className={`relative text-lg tracking-wide py-1 hover:text-nord8 transition-all duration-300 ${
                    activeSection === item.id
                      ? "text-nord8 font-semibold"
                      : "text-nord6"
                  }`}
                >
                  {item.label}
                  {activeSection === item.id && (
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-nord8 to-nord9 transition-all duration-300"></span>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="absolute bottom-0 left-0 h-1 bg-gradient-to-r from-nord8 to-nord9 transition-all"
          style={{ width: `${scrollProgress}%` }}
        ></div>
      </nav>

      <main className="max-w-6xl mx-auto px-4 pt-20">
        <section id="home" className="min-h-screen flex items-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="space-y-8">
              <h1 className="text-6xl font-extrabold mb-4 animate-fade-in-down">
                Hi, I'm <span className="text-nord7">Rakshit Rautela</span>
              </h1>
              <p className="text-3xl text-nord4 animate-fade-in-up delay-300">
                I'm a{" "}
                <TypewriterEffect
                  texts={[
                    "Bug Bounty Hunter",
                    "Web Developer",
                    "Software Engineer",
                  ]}
                />
              </p>
              <a
                href="#contact"
                className="inline-block bg-nord7 text-nord0 px-8 py-4 rounded-full text-lg font-semibold hover:bg-nord8 transition-colors shadow-lg hover:shadow-xl transform hover:-translate-y-1 animate-fade-in-up delay-600"
              >
                Get In Touch
              </a>
            </div>
            <div className="animate-fade-in-left delay-900">
              <img
                src="hero1.png"
                alt="Rakshit Rautela"
                className="w-85 rounded-full shadow-2xl animate-waving"
              />
            </div>
          </div>
        </section>
        <section
          id="about"
          className="py-10"
          onMouseEnter={() => setAnimationState(true)}
          onMouseLeave={() => setAnimationState(false)}
        >
          <h2 className="text-4xl font-bold mb-12 text-center text-nord7">
            About Me
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-start">
            <div
              className={`space-y-6 text-lg bg-nord1 p-8 rounded-xl shadow-lg transition-transform duration-500 ${
                animationState ? "transform rotate-1" : "animate-fade-in-right"
              }`}
            >
              <h3 className="text-2xl font-semibold text-nord8 mb-4">
                Software Developer
                <div className="flex space-x-4 mt-6">
                  <a
                    href="https://github.com/r0ckYr"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nord8 hover:text-nord9 transition-colors duration-300"
                  >
                    <Github />
                  </a>
                </div>
              </h3>
              <p>
                I am a Computer Science student at Graphic Era Hill University
                with expertise in Java, Python, and Javascript.
              </p>
              <p>
                I'm proficient in Linux and have deployed applications on AWS. I
                have expertise in CI/CD automation using Jenkins, which enhances
                development efficiency. I enjoy exploring new technologies and
                constantly aim to improve my skill set.
              </p>
            </div>
            <div
              className={`space-y-6 text-lg bg-nord2 p-8 rounded-xl shadow-lg transition-transform duration-500 ${
                animationState ? "transform -rotate-1" : "animate-fade-in-left"
              }`}
            >
              <h3 className="text-2xl font-semibold text-nord8 mb-4">
                Bug Bounty Hunter
                <div className="flex space-x-4 mt-6">
                  <a
                    href="https://hackerone.com/r0ckyr"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-nord8 hover:text-nord9 transition-colors duration-300"
                  >
                    <Skull size={24} />
                  </a>
                </div>
              </h3>
              <p>
                As a bug bounty hunter, I actively participate in cybersecurity
                programs, identifying and reporting vulnerabilities in web
                applications and networks. I have found critical security flaws
                for companies like Coinbase, Visa, and PayPal.
              </p>
              <p>
                My expertise includes Web Pentesting, Android Pentesting, and
                Source code review.
              </p>
            </div>
          </div>
          <div className="text-center mt-12">
            <a
              href="RakshitResume.pdf"
              className={`inline-block text-white py-3 px-6 rounded-lg shadow-lg hover:bg-nord10 transition-colors duration-300 ${
                animationState ? "bg-nord9" : "bg-nord0"
              }`}
              download
            >
              Resume
            </a>
          </div>
        </section>
        <section id="experience" className="py-10">
          <h2 className="text-4xl font-bold mb-12 text-center text-nord7">
            Experience
          </h2>
          {experiences.map((exp, index) => (
            <div
              key={index}
              className="mb-12 bg-nord1 rounded-xl p-8 shadow-lg hover:shadow-xl transition-shadow animate-fade-in-up"
            >
              <h3 className="text-2xl font-semibold text-nord8 mb-2">
                {exp.title}
              </h3>
              <p className="text-xl mb-1">{exp.company}</p>
              <p className="text-sm text-nord4 mb-4">{exp.date}</p>
              <ul className="list-disc pl-5 space-y-2">
                {exp.responsibilities.map((resp, idx) => (
                  <li key={idx}>{resp}</li>
                ))}
              </ul>
            </div>
          ))}
        </section>
        <section id="projects" className="py-10">
          <h2 className="text-4xl font-bold mb-12 text-center text-nord7">
            Projects
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {projects.map((project, index) => (
              <div
                key={index}
                className="bg-nord1 p-8 rounded-xl shadow-lg transition-transform duration-300 hover:shadow-xl hover:scale-105 animate-fade-in-up"
              >
                <div className="transform transition-transform duration-300 hover:scale-110">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-48 object-cover rounded-lg mb-6"
                  />
                  <h3 className="text-2xl font-semibold text-nord8 mb-4">
                    {project.title}
                  </h3>
                  <p className="mb-6 text-nord4">{project.description}</p>
                  <div className="flex space-x-4">
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-nord7 hover:text-nord8 inline-flex items-center transition-colors duration-300"
                    >
                      <Code size={18} className="mr-1" /> Source Code
                    </a>
                    {project.liveLink && (
                      <a
                        href={project.liveLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-nord7 hover:text-nord8 inline-flex items-center transition-colors duration-300"
                      >
                        <ExternalLink size={18} className="mr-1" /> Live Project
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section id="contact" className="py-10 my-32">
          <h2 className="text-4xl font-bold mb-12 text-center text-nord7">
            Get In Touch
          </h2>
          <div className="max-w-2xl mx-auto text-center">
            <p className="mb-12 text-xl">
              I'm currently looking for new opportunities. Whether you have a
              question or just want to say hi, I'll try my best to get back to
              you!
            </p>
            <div className="flex justify-center space-x-8">
              <a
                href="https://github.com/r0ckYr"
                target="_blank"
                rel="noopener noreferrer"
                className="text-nord7 hover:text-nord8 transition-colors transform hover:scale-110"
              >
                <Github size={32} />
              </a>
              <a
                href="https://www.linkedin.com/in/rakshitrautela7"
                target="_blank"
                rel="noopener noreferrer"
                className="text-nord7 hover:text-nord8 transition-colors transform hover:scale-110"
              >
                <Linkedin size={32} />
              </a>
              <a
                href="https://twitter.com/r0ckYrp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-nord7 hover:text-nord8 transition-colors transform hover:scale-110"
              >
                <Twitter size={32} />
              </a>
              <a
                href="mailto:rakshitrautela17@gmail.com"
                className="text-nord7 hover:text-nord8 transition-colors transform hover:scale-110"
              >
                <Mail size={32} />
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-nord1 text-center py-8">
        <p className="text-nord4">
          &copy; 2024 Rakshit Rautela. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Portfolio;
